import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'homePage',
      component: () => import('./components/homePage.vue')
    },
    {
      path: '/cfs',
      name: 'cfsPage',
      component: () => import('./components/cfs.vue')
    },
    {
      path: '/cfs-tariff',
      name: 'cfstariff',
      component: () => import('./components/cfstariff.vue')
    },
    {
      path: '/newsletter',
      name: 'Newsletterpage',
      component: () => import('./components/Newsletter.vue')
    },
    {
      path: '/services',
      name: 'Servicespage',
      component: () => import('./components/Services.vue')
    },
    {
      path: '/about',
      name: 'Aboutpage',
      component: () => import('./components/About.vue')
    },
    {
      path: '/origin',
      name: 'Originpage',
      component: () => import('./components/origin.vue')
    }
  ]
})

